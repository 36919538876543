import { StaticImage } from 'gatsby-plugin-image'
import React, { useState} from 'react'

import styled from 'styled-components'
import ArrowDownIcon from '../Icons/arrowDownIcon'
import PhoneIcon from '../Icons/PhoneIcon'
import PrimaryButton from '../Button/PrimaryButton'
import BreakpointDown from '../Media/BreakpointDown'
import BgImage from '../../images/how-we-can-help-pattarn.png'
import GetinTouchIcon from '../../svg/getintocuh.svg'
import QuotePopup from  '../../components/QuotePopup'


const HowCanWeHelpWrapper = styled.div`
  position:fixed;
  right:0;
  z-index:3;
  max-width:450px;
  width:100%;
  bottom: -152px;
  transition:bottom 0.4s ease-in-out;
  ${BreakpointDown.md`
     bottom: -145px;
 `}
 &.show{
  bottom: 0px;
  .help-img{
    bottom:0px;
  }
  .title{
    svg{
      transform:scaleY(1);
    }
  }
}
 
`
const Title = styled.div`
    background: #000;
    color: #fff;
    padding: 15px 25px 18px 35px;
    clip-path: polygon(8% 0%, 95% 0%, 100% 100%, 0% 100%);
    border-radius:30px 30px 0 0;
    font-family: "Nunito Sans", sans-serif;
    font-size:16px;
    font-weight:900;
    line-height:1;
    cursor: pointer;
    display:inline-flex;
    align-items: center;
    justify-content:center; 
    margin-left: 14%;
    margin-right: auto;
    position:relative;
    z-index:4;
    ${BreakpointDown.md`
      margin-left: 10%;
      font-size:14px;
      padding: 10px 15px 10px 25px;
      max-width: 240px;
    `}
  svg{
    line-height:1;
    fill:#529A00;
    width:18px;
    height:18px;
    margin-left:10px;
    transform:scaleY(-1);
    transition:all 0.4s ease-in-out;
  }
`
const HowCanWeHelpDetail = styled.div`
  background:#73A935 url(${BgImage});
  background-position:left;
  background-repeat:no-repeat;
  display:flex;
  padding:20px;
  align-items:center;
  justify-content:center;
  flex-direction:column;
  padding-right:80px;
  position:relative;
  ${BreakpointDown.md`
   padding-right:100px;
   background-position: -14% 100%;
  `}
 .btn{
    padding: 6px 18px;
    min-width:180px;
    .icon{
      line-height:1;
      svg{ line-height:1; width:18px; height:18px;}
    }
  }
`
const BtnWrapper = styled.div`
  margin-top:12px;
`
const HowCanHelpImg = styled.div`
  position:absolute;
  right:-15px;
  bottom:-75px;
  max-height:230px;
  transition:bottom 0.5s ease-in-out;
 ${BreakpointDown.md`
    max-height:200px;
 `}
`

function HowCanWeHelp() {
  const [showHelp, setShowHelp] = useState(false) 
  const [isVisibleQuote, setisVisibleQuote] = useState(false) 
  function ToggleHelp() {
    setShowHelp(!showHelp);
  }
  function HandleModalOpen() {
    setisVisibleQuote(true)
  }
  function HandleModalClose() {
    setisVisibleQuote(false)
  }
  
  return (
    <>
    <HowCanWeHelpWrapper className={showHelp ? 'show' : ''}>
      <Title className="title" onClick={ToggleHelp}>How Can We Help You! <ArrowDownIcon /></Title>
      <HowCanWeHelpDetail>
          <HowCanHelpImg className="help-img">
            <StaticImage
              src="../../images/how-can-help.png"
              quality={100}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Industry"
              placeholder="blurred"
            />
          </HowCanHelpImg>
          <a href="tel:3368990555"><PrimaryButton role="button"  aria-label="call us" type="button" iconBefore={true} icon={<PhoneIcon />} text="(336) 899-0555" /></a>
          <BtnWrapper  onClick={HandleModalOpen}>
            <PrimaryButton role="button" aria-label="GET IN TOUCH" type="button" iconBefore={true} icon={<img src={GetinTouchIcon} alt="Get in Touch" />} text="GET IN TOUCH" />
          </BtnWrapper>
      </HowCanWeHelpDetail>
      </HowCanWeHelpWrapper>
      <QuotePopup isVisible={isVisibleQuote} onClose={HandleModalClose} />
      </>
  )
}
export default HowCanWeHelp; 