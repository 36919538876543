import React from 'react'
import { Link } from 'gatsby'
import { ProductMenuWrapper, ProductMenuRow, ProductMenuColumn, BrandList, BrandListItem, BrandTitle } from './drop-down'

function ServiceDropDownMenu() {
  return (
    <ProductMenuWrapper className="dropdown-menu" width="600px">
      <ProductMenuRow>
        <ProductMenuColumn>
          <BrandTitle>Services</BrandTitle>
          <BrandList>
            <BrandListItem><Link to='/access-control-installation-and-hardware-sales'>Access Control Installation & Hardware Sales</Link></BrandListItem>
            <BrandListItem><Link to='/access-point-Installation-wireless-heat-mapping'>Access Point Installation & Wireless Heat Mapping</Link></BrandListItem>
            <BrandListItem><Link to='/business-telephone-systems-and-contact-centers'>Business Telephone Systems & Contact Centers</Link></BrandListItem>
            <BrandListItem><Link to='/digital-Signage-installation'>Digital Signage Installation</Link></BrandListItem>
            <BrandListItem><Link to='/interactive-whiteboards-installation'>Interactive Whiteboard Installation</Link></BrandListItem>
            {/* <BrandListItem><Link to='/project-management-for-equipment-installations'>Project Management for Equipment Installations</Link></BrandListItem> */}
            <BrandListItem><Link to='/isp-service-brokerage-and-cellular-network-backup-solutions'>ISP Service Brokerage & Cellular Network Backup Solutions</Link></BrandListItem>
            <BrandListItem><Link to='/new-site-construction-installation'>New Site Construction/Remodel/Move Equipment Installations & Service</Link></BrandListItem>
            {/* <BrandListItem><Link to='/nvr-camera-surveillance-system-installation-and-sales'>NVR & Camera Surveillance System Installation and Sales</Link></BrandListItem> */}
            <BrandListItem><Link to='/point-of-sale-installation-and-design'>Point-of-Sale Installation & Design</Link></BrandListItem>
            <BrandListItem><Link to='/staging-storage-inventory-and-depot-repair-services'>Staging/Configuration of IT Hardware/Peripherals</Link></BrandListItem>
            <BrandListItem><Link to='/structured-low-voltage-cabling-network-data-voice'>Structured Low Voltage Cabling (Network/Data/Voice) Design & Installation</Link></BrandListItem>
            <BrandListItem><Link to='/thermal-temperature-monitoring-installation'>Thermal Temperature Monitoring Solution Installation</Link></BrandListItem>
            {/* <BrandListItem><Link to='/data-security-and-cloud-business-solutions'>Data Security and Cloud Business Solutions</Link></BrandListItem> */}
            <BrandListItem><Link to='/vehicle-gps-tracking-systems-and-driver-safety'>Vehicle GPS Tracking Systems & Driver Safety Technology</Link></BrandListItem>
          </BrandList>
        </ProductMenuColumn>
      </ProductMenuRow>
    </ProductMenuWrapper>
  )
}

export default ServiceDropDownMenu;
