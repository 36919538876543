import React, { useState, useEffect } from 'react'
// import { StaticImage } from 'gatsby-plugin-image'
import styled from 'styled-components'
import { Link } from 'gatsby'
// import PrimaryLinkButton from '../Button/PrimaryLinkButton'
import BreakpointDown from '../Media/BreakpointDown'
import {brandMenu} from './menudata'

import { ProductMenuWrapper, ProductMenuRow, ProductMenuColumn, BrandList, BrandListItem, BrandTitle, ProductMenuColumninner, ProductMenuColumnItem, SubCtegoryList, SubCategoryListItem } from './drop-down'
import PlusIcon from '../Icons/PlusIcon';
import CallInfo from '../Icons/infoIcon';
import { ComingSoon } from '../Section'

const MobileProductMenu = styled(ProductMenuWrapper)`
  &.product-menu-mobile {
    display: none;
    padding-top: 20px;
    background: #eaeaea;
    ${BreakpointDown.xl`
         display:inherit;
      `}
    .brand-item {
      padding-left: 0;
      padding-right: 0;
      padding-top:2px;
      padding-bottom:2px;
      font-weight: 900;
       /* max-height: 60px; 
       overflow: hidden; */

      transition: all 0.35s;
      .item {
        svg {
          transition: all 0.1s linear;
          margin-right: 6px;
          path {
            fill: rgb(115, 169, 53);
          }
        }
      }
      &.active {
        /* max-height: 1000px;
        overflow: auto; */
        .product-menu-column{display:block}
        .item {
          svg {
            transform: rotate(-45deg);
            path {
              fill: #000;
            }
          }
        }
        .brand-list-item{ display:block;}
      }
      .brand-title {
        padding-left: 40px;
        ${BreakpointDown.xl`
        padding-left:44px
       `}
      }
      .brand-list-item {
        padding-left: 0;
        padding-right: 0;
      
      }
      .product-menu-column {  
        padding-top: 10px;
        display:none;
        border-bottom: 1px #d6d6d6 solid;
        padding-bottom: 30px;
        margin-bottom: 30px;
        margin-top: 6px;
        box-shadow: none;
        flex: 100%;
        max-width: 100%;
        padding: 35px 15px;
        border: 0;
        max-height: 100%;
        overflow: inherit;
        ul {
          li {
            padding-left: 0;
            padding-right: 0;
            font-size: 12px;
            > svg {
              display: none;
            }
          }
        }
        &:last-child {
          padding: 0;
          border-bottom: 0;
          margin-bottom: 0;
        }
      }
      .sub-category-item {
        max-height: 0;
        overflow: hidden;
        transition: all 0.35s;
        position: relative;
        &.active {
          max-height: 1000px;
          overflow: inherit;
          background: #fff;
          box-shadow: 0px 10px 35px #00000029;
          margin-top: -4px;
          &:before {
            top: -9px;
            left: 21px;
            position: absolute;
            content: '';
            width: 0;
            height: 0;
            border-left: 10px transparent solid;
            border-right: 10px transparent solid;
            border-top: 0;
            border-bottom: 10px #fff solid;
          }
        }
      }
      a{
        padding-left:0; padding-right:0;
      }
    }
    .sub-category-list {
      padding: 0px 15px;
      justify-content: flex-start;
      flex-direction: column;
    }
    .brand-logo {
      margin-top: 30px;
      margin-bottom: 20px;
      max-width: 120px;
    }
    .about-prodcut {
      font-size: 14px;
      margin-bottom: 20px;
      padding: 25px;
      text-align: center;
    }
  }
`

const BrandDetail = styled.div`
  width: 12px;
  height: 12px;
  margin-right: 10px;
  svg {
    display: inline-block;
    position: static;
  }
     a{padding: 0px 30px;}
`
const BrandItem = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  align-items: baseline;
  padding-left: 20px;
  padding-right: 20px;
  padding-top:10px;
  padding-bottom:10px;
`
const SubCtegoryListMobile = styled(SubCtegoryList)`
 margin-top:0;
`



function ProductMenuMobile() {
  const [activeTab, setactiveTab] = useState(0);
  const [brandInfo, setbrandInfo] = useState(false);
  const [inPhones, setInPhones] = useState('');
  // const [desc, setDesc] = useState('');
  // const [brandSystem, setbrandSystem] = useState(false);
  function HandleTabs(key, brand) {
    let filterMenu = brandMenu.filter((info) => info.brand === brand)
    setInPhones(filterMenu[0]);
    console.log(filterMenu[0].InPhones);
    setactiveTab(key);
    // setbrandSystem(false);
  };
  function HandleBrandInfo(key) {
    setbrandInfo(key);
  }
  // function HandleBrandSystem(key) {
  //   setbrandSystem(key);
  //   setbrandInfo(false);
  // }
  useEffect(() => {
    setInPhones(brandMenu[0]);
  }, [])
  return (
    <MobileProductMenu width="100%" className="product-menu-mobile">
      <ProductMenuRow>
        <ProductMenuColumn>
          <BrandTitle>Brand</BrandTitle>
          <BrandList>
            {brandMenu.map((item, index) => (
              <BrandListItem key={index} className={index === 0 ? activeTab === index && index === 0 ? 'brand-item active' : 'brand-item' : activeTab === index ? 'brand-item active' : 'brand-item'} onMouseOver={() => HandleTabs(index, item.brand)}>
                <BrandItem className="item"><PlusIcon /> {item.brand}</BrandItem>
                <ProductMenuColumn className="product-menu-column">
                  <ProductMenuColumninner index={index}>
                    <ProductMenuColumnItem className="brand-list-item">
                      <BrandTitle className="brand-title">IP Phones</BrandTitle>
                      <SubCtegoryListMobile className="sub-category">
                        {inPhones && inPhones.InPhones.map((items, index) => (
                          <SubCategoryListItem key={index} className="sub-category-list">
                            <BrandItem>
                              <BrandDetail onClick={() => HandleBrandInfo(index)}> <CallInfo /> </BrandDetail> <Link to={item.link}>{items.name}</Link>
                            </BrandItem>
                            <ProductMenuColumnItem className={`sub-category-item ${brandInfo === index ? 'active' : ''}`} key={index}>
                              {/* <BrandLogo className="brand-logo">
                                <StaticImage
                                  src="../../images/phone-reciever.png"
                                  quality={95}
                                  formats={["AUTO", "WEBP", "AVIF"]}
                                  alt="Case Study"
                                  placeholder="blurred"
                                />
                              </BrandLogo> */}
                              {/* <AboutProduct className="about-prodcut">
                                The ZIP 47G combines a hi-resolution color display and dual Gigabit Ethernet ports in a feature rich IP phone ideal for busy executives and heavy phone users. Functions and contacts may be accessed quickly via 27 programmable soft keys.
                                <ButtonWraper>
                                  <PrimaryLinkButton text="View Details" />
                                </ButtonWraper>
                              </AboutProduct> */}
                               <div style={{display:'flex', alignItems:'center', justifyContent:'center', height:'400px'}}><ComingSoon>Coming Soon..</ComingSoon></div>
                            </ProductMenuColumnItem>
                          </SubCategoryListItem>
                        ))}
                      </SubCtegoryListMobile>
                    </ProductMenuColumnItem>
                    {/* <ProductMenuColumnItem>
                      <BrandTitle className="brand-title">IP Phones Systems</BrandTitle>
                      <SubCtegoryListMobile>
                        {[1, 2].map((item, index) => (
                          <SubCategoryListItem key={index} className="sub-category-list">
                            <BrandItem>
                              <BrandDetail onClick={() => HandleBrandSystem(index)}> <CallInfo /> </BrandDetail> <Link to="/brand-detail">ZIP 43G Exceptional Value in a Feature Rich IP Phone </Link>
                            </BrandItem>
                            <ProductMenuColumnItem className={`sub-category-item ${brandSystem === index ? 'active' : ''}`} key={index}>
                              <BrandLogo className="brand-logo">
                                <StaticImage
                                  src="../../images/phone-reciever.png"
                                  quality={95}
                                  formats={["AUTO", "WEBP", "AVIF"]}
                                  alt="Case Study"
                                  placeholder="blurred"
                                />
                              </BrandLogo>
                              <AboutProduct className="about-prodcut">
                                The ZIP 47G combines a hi-resolution color display and dual Gigabit Ethernet ports in a feature rich IP phone ideal for busy executives and heavy phone users. Functions and contacts may be accessed quickly via 27 programmable soft keys.
                                    <ButtonWraper>
                                  <PrimaryLinkButton text="View Details" />
                                </ButtonWraper>
                              </AboutProduct>
                            </ProductMenuColumnItem>
                          </SubCategoryListItem>
                        ))}
                      </SubCtegoryListMobile>
                    </ProductMenuColumnItem> */}
                  </ProductMenuColumninner>
                </ProductMenuColumn>
              </BrandListItem>
            ))}
          </BrandList>
        </ProductMenuColumn>
      </ProductMenuRow>
    </MobileProductMenu>
  )
}

export default ProductMenuMobile;
