import styled from "styled-components";
import BreakpointDown from "../Media/BreakpointDown";
import BreakpointUp from "../Media/BreakpointUp";

export const Section = styled.section`
  padding-top: ${(props) => props.pt};
  padding-bottom: ${(props) => props.pb};
  background-image: ${(props) => props.bgImg};
  background-color: ${(props) => props.bgColor};
  background-size: ${(props) => props.bgSize};
  margin-top: ${(props) => props.mt};
  height: ${(props) => props.ht};
  text-align: ${(props) => props.textAlign};
  border-bottom: 1px solid ${(props) => props.bdrBottom};
  border-top: 1px solid ${(props) => props.bdrTop};
  position: relative;
  &:before {
    display: ${(props) => (props.bgBefore ? "block" : "none")};
    content: "";
    position: absolute;
    top: 0;
    background: ${(props) => props.bgBefore};
    background-size: ${(props) => props.bgBeforeSize};
    background-repeat: no-repeat;
    background-position: left;
    width: 100%;
    height: 100%;
  }
  &:after {
    display: ${(props) => (props.bgAfter ? "block" : "none")};
    position: absolute;
    content: "";
    right: 0;
    top: 0;
    background: ${(props) => props.bgAfter};
    background-size: ${(props) => props.bgBeforeSize};
    background-repeat: no-repeat;
    background-position: right;
    width: 100%;
    height: 100%;
  }
 
  ${BreakpointDown.xl`
		padding-top: ${(props) => props.xpt};
		padding-bottom: ${(props) => props.xpb};
	`}
  ${BreakpointDown.md`
		padding-top: ${(props) => props.mpt};
		padding-bottom: ${(props) => props.mpb};
	`}
  @media (min-width:2200px){
		padding-top: ${(props) => props.xxpt};
		padding-bottom: ${(props) => props.xxpb};
	}
`;
Section.defaultProps = {
  bgImg: "inherit",
  bgColor: "white",
  bgSize: "inherit",
  textAlign: "left",
  bdrBottom: "transparent",
  bdrTop: "transparent",


};

export const SectionSubTitle = styled.span`
  display: inline-block;
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  max-width: ${(props) => props.maxWidth};
  color: ${(props) => props.color};
  margin-top: ${(props) => props.mt};
  margin-bottom: ${(props) => props.mb};
  position: relative;
  z-index: 1;
  text-transform: uppercase;
  &:after {
    display: inline-block;
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    right: 0;
    height: 8px;
    background-color: rgba(245, 142, 145, 0.5);
    z-index: -1;
  }
`;

SectionSubTitle.defaultProps = {
  color: "#000",
  maxWidth: "100%",
  mb: "10px",
};

export const SectionTitle = styled.h2`
  color: ${(props) => props.color};
  text-align: ${(props) => props.textAlign};
  margin-top: ${(props) => props.mt};
  margin-bottom: ${(props) => props.mb};
  margin-left: ${(props) => props.ml};
  margin-right: auto;
  width: 100%;
  ${BreakpointUp.xl`
	  max-width: ${(props) => props.maxWidth};
	`}
  ${BreakpointDown.lg`
		text-align: ${(props) => props.lgTextAlign};
	`}
`;

SectionTitle.defaultProps = {
  textAlign: "center",
  color: "#000",
  maxWidth: "100%",
  mt: "auto",
  ml: "auto",
  mb: "30px",
};

export const SectionPageTitle = styled.h1`
  max-width: ${(props) => props.maxWidth};
  color: ${(props) => props.color};
  text-align: ${(props) => props.textAlign};
  margin-top: ${(props) => props.mt};
  margin-bottom: ${(props) => props.mb};
  margin-left: ${(props) => props.ml};
  margin-right: auto;
  width: 100%;

  ${BreakpointDown.lg`
		text-align: ${(props) => props.lgTextAlign};
	`}
`;

SectionPageTitle.defaultProps = {
  color: "#000",
  maxWidth: "100%",
  mt: "auto",
  ml: "auto",
  mb: "10px",
  textAlign: "center",
};




export const SectionDescription = styled.div`
  margin-left: auto;
  margin-right: auto;
  text-align: ${(props) => props.textAlign};
  margin-bottom: ${(props) => props.mb};
  max-width: ${(props) => props.maxWidth};
  width: 100%;
  & p {
    font-size: 18px;
    line-height: 30px;
    ${BreakpointDown.lg`
			font-size: 16px;
			line-height: 28px;
		`}
    + p {
      margin-top: 10px;
    }
  }
  ${BreakpointDown.lg`
		text-align: ${(props) => props.lgTextAlign};
	`}
`;
SectionDescription.defaultProps = {
  textAlign: "center",
  mb: "30px",
  maxWidth: "100%",
};

export const BreadCrumb = styled.section`
  font-size: 12px;
  line-height: 28px;
  color: #999999;
  position: relative;
  z-index: 2;
  top: ${(props) => props.top};
  margin-bottom: ${(props) => props.mb};
  ${BreakpointDown.xl`
		top: ${(props) => props.xtop};
	`}
  
  & a {
    color: #333333;
    /* display: inline-block;
    vertical-align: middle; */  
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1.8px;
    font-family: 'Nunito Sans', sans-serif;
    text-transform: uppercase;
    ${BreakpointDown.md`
		font-size:11px;
    letter-spacing: 0.8px;
	`}
   
    &:after{
      content:'//';
      display:inline-block;
      margin-left:10px;
      margin-right:10px;
      ${BreakpointDown.md`
      margin-left:5px;
      margin-right:5px;
	`}
    }
    &[href*="#"]{
      color: #999999;
      cursor: default;
      pointer-events:none;
      &:hover{
        color: #999999;
      }
    }
    &:last-child {
      &:after{
        display:none;
      }
    }
    & svg {
      width: 20px;
      display: inline-block;
      vertical-align: middle;
      fill: #b1b1b1;
      margin-right: 2px;
    }
    &:hover,
    &:focus {
      color: #000;
      svg {
        fill: #000;
      }
    }
  }
  & span {
    display: inline-block;
    padding: 0 2px;
    color: #eb1f24;
    font-weight: 500;
  }
`;
BreadCrumb.defaultProps = {
  top: "0px",
  mb: "0px",
};

export const Badge = styled.div`
  display: inline-flex;
  align-items: flex-end;
  position: absolute;
  z-index: 1;
  border-right: 15px solid transparent;
  height: 0;
  min-width: 92px;
  top: ${(props) => props.top};
  right: ${(props) => props.right};
  bottom: ${(props) => props.bottom};
  left: ${(props) => props.left};
  color: ${(props) => props.color};
  border-top: ${(props) => props.bdrWidth} solid ${(props) => props.bdrColor};
  font-size: ${(props) => props.fontSize};
  line-height: ${(props) => props.lineHeight};
  strong {
    display: block;
    white-space: nowrap;
    padding: 4px 12px;
    position: relative;
    font-weight: 500;
  }
`;
Badge.defaultProps = {
  color: "#fff",
  bdrColor: "#000",
  bdrWidth: "26px",
  top: "0",
  right: "auto",
  bottom: "auto",
  left: "0",
  fontSize: "14px",
  lineHeight: "18px"
};


export const BaseButton = styled.span`
  display: inline-block;
  text-align: center;
  padding: 10px 18px;
  font-weight: 500;
  border-radius: 5px;
  position: relative;
  vertical-align: middle;
  border: 1px solid transparent;
  background-color: 700ms linear, color 700ms linear;
  cursor: pointer;
  transition: 0.2s all;
  ${BreakpointDown.md`
	  padding: 6px 18px;
    font-size: 14px; 
	`}
  @media (hover: hover) {
    &:hover {
      > .icon {
        /* left: 10px; */
      }
    }
  }
  >.icon{
    + .text{
      margin-left:10px; 
    }
  }
  > .text {
    line-height: 24px;
    display: inline-block;
    vertical-align: middle;
    text-transform: uppercase;
    font-weight: 900;
    transition: all;
    letter-spacing: 1.8px;
    font-family: 'Nunito Sans', sans-serif;
    z-index:1;
    position:relative;

    + .icon {
      margin-left: 10px;
      ${BreakpointDown.md`
	      margin-left:5px;
	    `}
    }
  > .icon {
    width: 35px;
    border-radius: 5px;
    display: inline-block;
    vertical-align: middle;
    line-height: 33px;
    position: relative;
    left: 5px;
    transition: 0.2s all;
    ${BreakpointDown.md`
				width: 30px;  
				line-height:30px;
	    `}
    &:empty {
      display: none;
    }
  }
}
`
export const BaseButtonIcon = styled.span`
  padding: 6px 18px;
  border-radius: 5px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  border: 1px solid transparent;
  text-align: left;
  display: inline-flex;
  background-color: 700ms linear, color 700ms linear;
  & .icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 5px;
    text-align: center;
    + .text {
      margin-left: 10px;
    }
  }
  & .text {
    display: inline-block;
    vertical-align: middle;
    line-height: 18px;

    & small {
      font-size: 16px;
      line-height: 18px;
    }
    & strong {
      display: block;
      font-size: 20px;
      line-height: 22px;
      font-weight: 600;
    }
  }
`;

export const ExploreMore = styled.div`
  display: block;
  text-align: center;
  margin-top: ${(props) => props.mt};
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: ${(props) => props.mb};
`;
ExploreMore.defaultProps = {
  mt: "30px",
  mb: "0px",
};

export const FormTwoCol = styled.div`
  max-width: 890px;
  width: 100%;
  margin: 0 auto;
  form {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    text-align: left;
  }
  .form-group {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    ${BreakpointUp.md`
      flex: 0 0 50%;
      max-width: 50%;
        `}
  }
  .form-textarea {
    ${BreakpointUp.md`
      flex: 0 0 100%;
      max-width: 100%;
        `}
  }
  .form-action {
    width: 100%;
  }
`;
export const SectionVerticalSubtitle = styled.div`
  position: absolute;
  height: 40px;
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  margin-left: ${(props) => props.ml};
  margin-right: ${(props) => props.mr};
  top: 50%;
  transform: rotate(-90deg) translateY(-50%);
  transform-origin: center;
  font-size: 42px;
  color: rgba(18, 19, 22, 0.1);
  display: flex;
  align-items: center;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  &:before,
  &:after {
    content: "";
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    background: rgba(18, 19, 22, 0.1);
    margin: 6px;
  }
  @media (max-width: 1440px) {
    margin-left: ${(props) => props.xxml};
    margin-right: ${(props) => props.xxmr};
    font-size: 24px;
  }
  @media (max-width: 1279px) {
    display: none;
  }
`;



export const Modal = styled.div`
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    width: 100%;
    height: 100%;
    overflow: auto;
    outline: 0;
`
Modal.defaultProps = {
}
export const CloseButton = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    top: 5px;
    right: 5px;
    width: 40px;
    height: 40px;
		background:#529A00;
		border:2px solid #fff;
    @media (min-width: 992px){
				top: -15px;
				right: -15px;
        width: 60px;
        height: 60px;
				border-radius:100%;
    }
    svg {
        fill: #fff;
        width: 16px;
        height: 16px;
				path{
					fill:#fff;
				}
        @media (min-width: 992px){
            width: 18px;
            height: 18px;
        }
    }
    &:hover {
        cursor: pointer;
        svg {
        fill: #000;
        }
    }
`
CloseButton.defaultProps = {}

export const ModalDialog = styled.div`
    position: relative;
    width: auto;
    pointer-events: none; 
`
ModalDialog.defaultProps = {}

export const ModalContent = styled.div`
    background-color: ${props => props.bgColor};  
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-clip: padding-box;
    outline: 0;
		
`
ModalContent.defaultProps = {
  bgColor: "#fff",
}
export const ModalHeader = styled.div`
    display: flex;  
    align-items: flex-start;  
    justify-content: space-between;
    padding: 30px 20px 20px;
    @media (min-width: 768px){
        padding:60px 30px 30px;
    }
    @media (min-width: 992px){
        padding: 60px;
    }
`
ModalHeader.defaultProps = {
}
export const ModalBody = styled.div`
    position: relative;
    flex: 1 1 auto;
    padding: 40px 20px 20px;
    @media (min-width: 768px){
        padding:60px 30px 30px;
    }
    @media (min-width: 992px){
        padding: 60px;
    }
`
ModalBody.defaultProps = {
}
export const ModalTitle = styled.div`

    color: #000;
    font-weight: 700;
    text-align: center;
    margin-bottom: 30px;
    font-size: 24px;
    line-height: 34px;
`
ModalTitle.defaultProps = {
}
export const ModalFooter = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end;
    padding:15px;
`
ModalFooter.defaultProps = {
}


export const Overlay = styled.div`
 position:fixed;
 left:0;
 top:0;
 right:0;
 bottom:0;
 background: #121316;
 opacity: 0.8;
 z-index:11;
`


export const MarkdownContent = styled.div`
  display: flex;
  flex-direction:column;
  border-bottom: 1px solid #cccccc;
  padding-top:50px;
  padding-bottom:50px;
  ${BreakpointDown.xl`
    padding-top:30px;
    padding-bottom:30px;
  `}
  &:last-child{
    border-bottom: 0;
    padding-bottom:0;
  }
  
  ul {
    margin-top: 10px;
    margin-bottom: 0;
    ${BreakpointDown.lg`
      margin-top: 20px;
      margin-bottom: 20px;
    `}
      ${BreakpointDown.md`
      margin-top: 0;
      margin-bottom: 0;
    `}
    li{
      padding-top:12px;
      padding-bottom:12px;
      ${BreakpointDown.lg`
          padding-top:8px;
          padding-bottom:8px;
      `}
    }
  }
`;

export const MarkdownContentWrapper = styled.div`
  max-width: 1600px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  a{color:#529A00; white-space: nowrap }
`;
export const MarkdownContentWrapperInner = styled.div`
  
  text-align: left;
  .btn {
    margin-bottom: 25px; 
  }
`;
export const MarkdownContentTitle = styled.div`
 text-align:center;
`
export const ComingSoon = styled.div`
 font-size:${(props) => props.fontSize};
 text-align:${(props) => props.textAlign};
 margin-top:120px;
 margin-bottom:120px;
`
ComingSoon.defaultProps = {
  fontSize: '42px',
  textAlign: 'left'
}
export const Tags = styled.span`
  display:inline-block;
  padding:6px 5px;
  background:#F1FFE0;
  border:1px solid #C2E09F;
  color:#73A935;
  font-size:12px; 
  border-radius:4px;
  line-height:1;
  margin-bottom:3px;
  margin-right:4px;
  &:hover{
    background:#fff;
    border-color:#121316;
    color:#121316;
  }
  ${BreakpointDown.md`
     padding:6px 8px;
  `}
  ${BreakpointDown.sm`
    padding: 5px 4px;
  `}
`
