import React from 'react'
import { Link } from 'gatsby'
import { ProductMenuWrapper, ProductMenuRow, ProductMenuColumn, BrandList, BrandListItem, BrandTitle} from './drop-down'



function IndusrtyDropDownMenu() {
  return (
    <ProductMenuWrapper width="400px" className="dropdown-menu">
      <ProductMenuRow>
        <ProductMenuColumn>
          <BrandTitle>Industries</BrandTitle>
          <BrandList>
              <BrandListItem><Link to='/aerospace'>Aerospace</Link></BrandListItem>
              <BrandListItem><Link to='/convenience-stores'>Convenience Stores</Link></BrandListItem>
              <BrandListItem><Link to='/education'>Education</Link></BrandListItem>
              <BrandListItem><Link to='/grocery'>Grocery</Link></BrandListItem>
              <BrandListItem><Link to='/hospitality'>Hospitality</Link></BrandListItem>
              <BrandListItem><Link to='/manufacturing'>Manufacturing</Link></BrandListItem>
              <BrandListItem><Link to='/medical'>Medical</Link></BrandListItem>
              <BrandListItem><Link to='/quick-serve-restaurants'>Quick Serve Restaurants</Link></BrandListItem>
              <BrandListItem><Link to='/retail'>Retail</Link></BrandListItem>
              <BrandListItem><Link to='/warehousing'>Warehousing</Link></BrandListItem>
          </BrandList>
        </ProductMenuColumn>
      </ProductMenuRow>
    </ProductMenuWrapper>
  ) 
}

export default IndusrtyDropDownMenu;
