import React from "react"
import styled, { keyframes } from 'styled-components'
import PropTypes from "prop-types"
import { Link } from "gatsby"
import BreakpointDown from "../components/Media/BreakpointDown"
import BreakpointUp from "../components/Media/BreakpointUp"
import PlusIcon from '../components/Icons/PlusIcon'
import PrimaryButton from './Button/PrimaryButton'
import { StaticImage } from "gatsby-plugin-image"
import Menuicon from '../components/Icons/MenuIcon'


import ProductMenu from './HeaderDropdown/product-menu'
import ServiceDropDownMenu from "./HeaderDropdown/service-dropdown"
import TechnologyDropDownMenu from "./HeaderDropdown/technology-dropdown"
import IndusrtyDropDownMenu from "./HeaderDropdown/industry-dropdown"
import ProductMenuMobile from './HeaderDropdown/product-menu-mobile'
import CloseIcon from "./Icons/CloseIcon"
import QuotePopup from '../components/QuotePopup'



const headerSticky = keyframes`
  from{
    transform:translateY(-140px);
  }
  top{
    transform:translateY(0);

  }
`

const HeaderWrapper = styled.header`
  transition:top 0.5s;
  top:40px;
  position:relative;
  z-index:1;
  position:absolute; 
  left:0;
  right:0;
  z-index:5;
  ${BreakpointDown.xl`
    .container{
      padding-left:0;
      padding-right:0;
    }
  `}
  ${BreakpointDown.xl`
    position:fixed;
    width:100%;
    margin-top:0px;
    left:0;
    top:0;
  }
  `}
  &.header-sticky{
    animation: ${headerSticky} 0.6s ease-in-out both;
    position: fixed;
    top:0;
}
 `
const HeaderRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items:center;
  background:#fff;
  max-width:calc(100% - 0px);
  .hamburger{
    line-height:1;
    margin-left:15px;
    transform:scaleX(-1);
    ${BreakpointUp.xl`
      display:none;
    `}
    }
`

const Navbar = styled.div`  
  padding:0px 10px;
  margin-left:auto;
  max-width:100%;
  width: 100%;
  box-shadow: 0px 15px 35px rgb(0, 0, 0, 0.20);
  display: flex;
  align-items: center;
  .close-menu{
    position:fixed;
    top: 15px;
    right: 15px;
    z-index:999;
    display:none;
     ${BreakpointDown.xl`
       display:block;
    `}
  }
  
  ${BreakpointUp.xl`
    margin: 0 auto 0 0;
  `}
  ${BreakpointDown.xl`
     padding:7px 10px;
  `}
`

const Nav = styled.nav`
  background: #fff;
  margin-left:auto;
  display: flex;
  align-items: center;
  padding: 0px 9px;
  flex: 1;
  ${BreakpointDown.xl`
    padding: 40px 0;  
    flex-direction: column-reverse;
    width: 0;
    position: absolute;
    top: 0;
    right: 0;
    height: 100vh;
    z-index: 99;
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1) 0s;
    transform: translate3d(100%,0,0);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 6px 15px;
    overflow-y: auto;
    display:block;
    background: #f4f4f4;
  `}
`

const NavBrand = styled(Link)`  
  display: flex;
  overflow: hidden;  
  align-items:center;
  text-align:left;
  padding:0 0px;
  width: 245px;
  max-width:245px;
  @media(max-width:1280px){
    max-width: 145px;
  }
  @media(max-width:1024px){
    max-width: 145px;
  }
  ${BreakpointDown.md`
   max-width: 100px;
  `}
  svg{width:100%} 
  & img {
    margin-bottom: 0;
    display: block;
  }
`
const NavbarNav = styled.div`
  display: flex;
  margin-left: auto;
  margin-right:0;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  align-items:center;
  justify-content:flex-end;
  width:100%;
  ${BreakpointDown.xl`
    flex-direction:column;
    justify-content:flex-start;
    margin:0;
    width:100%;
    flex: auto;
    margin-top:50px;
  `}
`

const NavItem = styled.div`
  margin:0;
  padding:26px 10px;
  position:relative;
  /* &:nth-child(3){
    position:static;
  } */
  @media(max-width:1280px){
    padding:16px 1px;
  }
  ${BreakpointDown.xl`
    padding:0px 1px;
  `}

  .btn{
    ${BreakpointDown.lg`
      padding: 6px 18px;
      font-size: 12px;
      min-width:150px;
      .text{letter-spacing: 0.48px;}
    `}
  }
  &:hover{
    cursor:pointer;
  }
  ${BreakpointDown.xl` 
    width:100%;
     border-top: 1px #d6d6d6 solid;
     &:last-child{
      border-bottom: 1px #d6d6d6 solid;
     }

  `}  
  &.active{
    & .nav-link{
      color:#EB1F24; 
      background-color:#FFF9F9;
    }
  }
  &:first-child{
    border-left:none;
  }
`
const NavLink = styled(Link)`
  display: flex;
  align-items:center;
  line-height: 26px;
  color:#000;
  cursor:pointer; 
  white-space:nowrap;
  padding:6px 8px;
  font-size:16px;  
  font-weight:bold;
  letter-spacing: 0.16px;
  position:relative;
  transition:all 0.2s linear;
  @media(max-width:1280px){
    font-size:16px;
  }
  ${BreakpointDown.xl`
    display:flex;
    justify-content:space-between;
    padding-left:20px;
    padding-right:20px;
    padding-top:20px;
    padding-bottom:20px;
  `}
  ${BreakpointDown.md`
    font-size:16px;
  `}
  svg{
     margin-left:6px;
     transition:all 0.1s linear;
    }
  &:hover, &.active{
    color:#529A00;
    ${BreakpointDown.xl`
       border-bottom: 1px #d6d6d6 solid;
       background:#eaeaea;
    `}
    svg{
      transform:rotate(45deg);
      path{
       fill:#000;
      }
    }
  }
`

const NavNoLink = styled.span`
  display: flex;
  align-items:center;
  line-height: 26px;
  color:#000;
  cursor:pointer; 
  white-space:nowrap;
  padding:6px 8px;
  font-size:16px;  
  font-weight:bold;
  letter-spacing: 0.16px;
  position:relative;
  transition:all 0.2s linear;
  @media(max-width:1280px){
    font-size:16px;
  }
  ${BreakpointDown.xl`
    display:flex;
    justify-content:space-between;
    padding-left:20px;
    padding-right:20px;
    padding-top:20px;
    padding-bottom:20px;
  `}
  ${BreakpointDown.md`
    font-size:16px;
  `}
  svg{
     margin-left:6px;
     transition:all 0.1s linear;
    }
  &:hover, &.active{
    color:#529A00;
    ${BreakpointDown.xl`
       border-bottom: 1px #d6d6d6 solid;
       background:#eaeaea;
    `}
    svg{
      transform:rotate(45deg);
      path{
       fill:#000;
      }
    }
  }
`
const LogoWrapper = styled.div`
  position:relative;
  max-width:200px;
  width:100%;
  line-height: 1;
  display: flex;
  align-items: center;
  @media(max-width:1280px){
    flex: 0 0 150px;
  }
  ${BreakpointDown.md`
   max-width:100%;
  `}
  `
const GetInTouchBtn = styled.div`
  margin-left:auto;
  .nav-link{padding: 6px 8px}
>div{
  border:0;
  &:last-child{
    border:0;
  }
}
div{
  padding-top:0;
  padding-bottom:0;
}

`
class Header extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isMenu: true,
      isVisibleQuote: false,
      isHeaderSticky: false,
      isServiceMenu: false,
      isTechnologiesMenu: false,
      isProductsMenu: false,
      isIndustriesMenu: false,
    }
    this.toggleActive = this.toggleActive.bind(this);
    this.headerSticky = this.headerSticky.bind(this);
    this.HandleModalOpen = this.HandleModalOpen.bind(this);
    this.HandleModalClose = this.HandleModalClose.bind(this);
  }
  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    document.addEventListener('scroll', this.headerSticky)
  }
  componentWillUnmount() {
    document.body.classList.remove('menu-open')
    document.removeEventListener("mousedown", this.handleClickOutside);
    document.removeEventListener('scroll', this.headerSticky)
  }
  headerSticky() {
    if (window.scrollY > 140) {
      this.setState({
        isHeaderSticky: true
      })
    }
    else {
      this.setState({
        isHeaderSticky: false
      })
    }
  }
  HandleModalOpen() {
    this.setState({
      isVisibleQuote:true
    })
  }
  HandleModalClose() {
    this.setState({
      isVisibleQuote:false
    })
  }
  
  toggleActive() {
    this.setState({
      isMenu: !this.state.isMenu
    })
    document.body.classList.toggle('menu-open', this.state.isMenu)
  }

  render() {
    const { isVisibleQuote, isHeaderSticky, isServiceMenu, isTechnologiesMenu, isProductsMenu, isIndustriesMenu } = this.state
    const PageName = this.props.location;
    return (
      <>
      <HeaderWrapper className={isHeaderSticky ? "header header-sticky" : "header"}>
        <div className="container">
            <HeaderRow>
              <Navbar>
                <NavBrand to="/">
                  <LogoWrapper>
                    <StaticImage
                      src="../images/logo.png"
                      quality={100}
                      formats={["AUTO", "WEBP", "AVIF"]}
                      alt="Logo"
                      placeholder="blurred"
                    />
                  </LogoWrapper>
                </NavBrand>
                <Nav className="nav">
                  <button className="close-menu" aria-label="Close Menu" onClick={this.toggleActive} ><CloseIcon /></button>
                  <NavbarNav>
                    <NavItem onMouseEnter={() => this.setState({ isServiceMenu: true })} onMouseLeave={() => this.setState({ isServiceMenu: false })}>
                      <NavNoLink  activeClassName="active" className={`nav-link ${isServiceMenu || PageName === 'service' ? 'active' : ''}`}>Services<PlusIcon /></NavNoLink>
                      {isServiceMenu && <ServiceDropDownMenu />}
                    </NavItem>
                    <NavItem onMouseEnter={() => this.setState({ isTechnologiesMenu: true })} onMouseLeave={() => this.setState({ isTechnologiesMenu: false })}>
                      <NavNoLink className={`nav-link ${isTechnologiesMenu || PageName === 'technology' ? 'active' : ''}`}>Technologies<PlusIcon /></NavNoLink>
                      {isTechnologiesMenu && <TechnologyDropDownMenu />}
                    </NavItem>
                    
                    <NavItem onMouseEnter={() => this.setState({ isIndustriesMenu: true })} onMouseLeave={() => this.setState({ isIndustriesMenu: false })}>
                      <NavNoLink  className={`nav-link ${isIndustriesMenu || PageName === 'industry' ? 'active' : ''}`}>Industries<PlusIcon /></NavNoLink>
                      {isIndustriesMenu && <IndusrtyDropDownMenu />}
                    </NavItem>
                    <NavItem>
                      <NavLink to="/case-studies" className={`nav-link ${PageName === 'case-studies' ? 'active' : ''}`}>Case Studies</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="/blog" className={`nav-link ${PageName === 'blog' ? 'active' : ''}`}>Resources</NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink to="/about-us" className={`nav-link ${PageName === 'about-us' ? 'active' : ''}`}>About WYN</NavLink>
                    </NavItem>
                  </NavbarNav>
                </Nav>
                <GetInTouchBtn onClick={this.HandleModalOpen}>
                    <NavItem>
                     <PrimaryButton role="button" aria-label="GET IN TOUCH" type="button" text="GET IN TOUCH" />
                  </NavItem>
                  </GetInTouchBtn>
                  <button className="hamburger" aria-label="Menu" onClick={this.toggleActive} ><Menuicon /></button>
              </Navbar>
            </HeaderRow>
        </div>
      </HeaderWrapper>
      <QuotePopup isVisible={isVisibleQuote} onClose={this.HandleModalClose} />
       </>
    )
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
