import React from 'react'

export default function PhoneIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="emergency-call" transform="translate(-0.001 -63.464)">
        <g id="Group_6979" data-name="Group 6979" transform="translate(0.001 63.464)">
          <path id="Path_19320" data-name="Path 19320" d="M23.453,80.659l-3.027-3.027a1.875,1.875,0,0,0-2.648,0l-1.8,1.8a.808.808,0,0,1-.971.131A18.914,18.914,0,0,1,7.9,72.461a.808.808,0,0,1,.131-.97l1.8-1.8a1.875,1.875,0,0,0,0-2.648L6.805,64.012a1.875,1.875,0,0,0-2.648,0L1.489,66.68a5.093,5.093,0,0,0-.39,6.757A78.48,78.48,0,0,0,14.028,86.366a5.093,5.093,0,0,0,6.757-.39l2.668-2.668a1.875,1.875,0,0,0,0-2.648ZM5.292,65.147a.268.268,0,0,1,.378,0L8.7,68.174a.268.268,0,0,1,0,.378l-.946.946L4.346,66.093ZM19.65,84.841a3.488,3.488,0,0,1-4.627.265A76.872,76.872,0,0,1,2.359,72.442a3.487,3.487,0,0,1,.265-4.627l.587-.587,3.428,3.428a2.417,2.417,0,0,0-.135,2.6,20.5,20.5,0,0,0,7.7,7.7,2.417,2.417,0,0,0,2.6-.135l3.428,3.428Zm1.722-1.722-3.405-3.405.946-.946a.268.268,0,0,1,.378,0l3.027,3.027a.268.268,0,0,1,0,.378Z" transform="translate(-0.001 -63.464)" fill="#fff" />
        </g>
      </g>
    </svg>
  )
}
