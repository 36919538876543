/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import "./scss/layout.scss"
import Header from "../components/header"
import Footer from '../components/footer'
import ProjectHelpForm from '../components/ProjectHelpForm'
import { useStaticQuery, graphql } from "gatsby"
import HowCanWeHelp from "./HowCanWeHelp"
const Layout = ({ children, pageName }) => {
  const data = useStaticQuery(graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} location={pageName} />
      <main>{children}</main>
      { pageName !== '404' &&
        pageName !== 'thank-you' &&
        pageName !== 'contact-us' &&
        <ProjectHelpForm />}
      {pageName !== '404' &&
      pageName !== 'thank-you' &&
        <Footer />}
      <HowCanWeHelp />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
