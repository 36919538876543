import React from 'react'
import { Link } from 'gatsby'
import { ProductMenuWrapper, ProductMenuRow, ProductMenuColumn, BrandList, BrandListItem, BrandTitle} from './drop-down'

function TechnologyDropDownMenu() {
  return (
    <ProductMenuWrapper width="500px" className="dropdown-menu">
      <ProductMenuRow>
        <ProductMenuColumn>
          <BrandTitle>Technologies</BrandTitle>
          <BrandList>
              <BrandListItem><Link to='/access-control-technology'>Access Control Solutions</Link></BrandListItem>
              <BrandListItem><Link to='/routers-firewalls-switches-access-points'>Routers/Firewalls, Switches, Access Points</Link></BrandListItem>
              <BrandListItem><Link to='/business-telephone-systems-contact-centers'>Business Telephone Systems & Contact Centers</Link></BrandListItem>
              <BrandListItem><Link to='/digital-signage'>Digital Signage</Link></BrandListItem>
              <BrandListItem><Link to='/interactive-whiteboards'>Interactive Whiteboards</Link></BrandListItem>
              <BrandListItem><Link to='/isp-brokerage-cellular-backup-solutions'>ISP Brokerage & Cellular Backup Solutions</Link></BrandListItem>
            <BrandListItem><Link to='/point-of-sale-solutions'>Point-of-Sale Solutions</Link></BrandListItem>
            <BrandListItem><Link to='/secure-data-management'>Secure Data Management</Link></BrandListItem>
              <BrandListItem><Link to='/structured-low-voltage-cabling-data-voice'>Structured Low Voltage Cabling (Data/Voice)</Link></BrandListItem>
              <BrandListItem><Link to='/thermal-temperature-monitoring-solution'>Thermal Temperature Monitoring Solution</Link></BrandListItem>
              <BrandListItem><Link to='/vehicle-gps-tracking-systems'>Vehicle GPS Tracking Systems</Link></BrandListItem>
          </BrandList>
        </ProductMenuColumn>
      </ProductMenuRow>
    </ProductMenuWrapper>
  )
}

export default TechnologyDropDownMenu;
