
import styled, { keyframes } from 'styled-components'
import BreakpointDown from '../Media/BreakpointDown';
import BreakpointUp from '../Media/BreakpointUp';

export const rotate = keyframes`
  from {
    right:-5px;
  }
  to {
    right:-6px;
  }
`;


export const ProductMenuWrapper = styled.div`
  position:absolute;
  left:0;
  right:0;
  top: 89px;
  width:${(props) => props.width};
  background:#fff;
  box-shadow: 0px 20px 85px #00000033;
  cursor:auto;
  @media(max-width:1280px){
    top:70px;
  }
  ${BreakpointDown.xl`
    position:static;
    box-shadow:none;
    width:100%;
  `}
    &.product-menu{
      right: 60px;
      left: 60px;

      @media(min-width:2400px){
        right:200px;
        left:200px;
      }
      @media(min-width:2400px){
        right:200px;
        left:200px;
      }
      ${BreakpointDown.xl`
        display:none;
      `}
    }
`
ProductMenuWrapper.defaultProps = {
  width: '300px'
}
export const ProductMenuRow = styled.div`
 display:flex;
 flex-wrap:wrap;

`
export const ProductMenuColumn = styled.div`
 flex:1;
 padding:52px 0;
 border-left:1px solid #CCCCCC; 
 box-shadow: inset 0 20px 20px -10px #00000033;
 max-height: 568px;
 overflow-y:auto;
 position:relative;
 ${BreakpointDown.xl`
  max-height:100%;
  overflow-y:inherit;
 `}
 &::-webkit-scrollbar-track
{
	box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  background-color: #9e9e9e;
}

&::-webkit-scrollbar
{
	width: 4px;
	background-color: #000;
}

&::-webkit-scrollbar-thumb
{
	background-color: #f4f4f4;
	border: 2px solid #000;
}

 &:first-child{
  flex:0 0 25%;
  width:25%;
  padding:52px 0px;
  background:#f4f4f4;
  border:0;
  ${BreakpointDown.xl`
  flex:0 0 100%;
  width:100%;
  background: #eaeaea; 
   `}
  &:only-child{
  flex:0 0 100%;
  width:100%;
  padding-left:0;
  padding-right:0;
  box-shadow: inset 0 20px 20px -15px #00000036;
  ${BreakpointDown.xl`
      box-shadow:none;
      flex:0 0 100%;
      width:100%;
  `}
}

 }
 &:last-child{
  flex:0 0 25%;
  width:25%;
  padding:52px 40px;
  /* max-height:100%; */
  ${BreakpointDown.xl`
    padding: 15px 40px;
    flex:0 0 100%;
    width:100%;
  `}
 }
`
export const ProductMenuColumnItem = styled.div`
   & + &{
     margin-top:40px;
     margin-bottom:40px;
   }
`
export const BrandTitle = styled.div`
  font-size:10px;
  color:#666666;
  text-transform:uppercase;
  padding-left:35px;
  letter-spacing: 2px;
  font-weight:900;
  ${BreakpointDown.xl`
   padding-left:20px;
  `}
`
export const BrandList = styled.ul`
  padding:0;
  margin:0;
  margin-top:10px;
`

export const BrandListItem = styled.li`
  list-style:none;
  cursor: pointer;
  font-family: "Nunito Sans", sans-serif;
  font-weight:bold;
 
  line-height:20px;
  font-size:16px;
  transition:0.3s all ease;
  margin:0;
  a{
     display:block;
      padding:14px 35px;
      color: #000;
      ${BreakpointDown.xl`
       padding:14px 30px;
      `}
  }
   svg{
      transition: all 0.1s linear;
      margin-right:6px;
      path{
        fill:#999999;
      }
   }
  &.active{
   ${BreakpointUp.xl`
    background:#FFFFFF;
  `}
}
  @media (hover: hover) {
    &:hover{
      background:#FFFFFF;
    }
  }
`

export const SubCtegoryList = styled.ul`
padding:0;
margin:0;
margin-top:10px;
`



export const SubCategoryListItem = styled.li` 
  list-style:none;
  cursor: pointer;
  font-family: "Nunito Sans", sans-serif;
  font-weight:bold;
  padding:14px 65px;
  line-height:1;
  font-size:16px;
  color:#999999;
  display:flex;
  justify-content:space-between;
  position:relative;
  transition:0.3s all ease;
  margin:0;
  >svg{
    transform: rotate(-90deg);
    position: absolute;
    right:0px;
    margin-right: 45px;
    display:none;
    animation-fill-mode:both;
    animation: ${rotate} 0.2s ease forwards;
    ${BreakpointDown.xl`
     display:none;
    `}
    path{
      fill:#000000;
    }
  }
&.active,
&:hover{
   color:#000000;
  svg{
    display:block;
  }
}
`
export const AboutProduct = styled.div`

 line-height:22px;
font-size:14px;
`
export const BrandLogo = styled.div`
 max-width:180px;
 margin-left:auto;
 margin-right:auto;
 margin-bottom:50px;
 position:relative;
 @media(max-width:1280px){
  margin-bottom:30px;
  max-width:160px; 
 }
 &:before{
   content:'';
   width:100%;
   height:100%;
   display:block;
   background:transparent;
   position:absolute;
   z-index:2;
 }
`
export const ProductMenuColumninner = styled.div`

`
export const ProductTitleAfter = styled.div`
    letter-spacing: 6.3px;
    color: #000000;
    text-transform: uppercase;
    opacity: 0.06;
    position: absolute;
    bottom: 0;
    text-align: center;
    left: 0;
    right: 0;
    text-align: center;
    font-size: 70px;
    font-family: "Nunito Sans",sans-serif;
    font-weight: 900;
    line-height: 57px;
    overflow: hidden;
    ${BreakpointDown.xl`
     display:none;
    `}
`
export const ButtonWraper = styled.div`
text-align:center;
margin-top:20px;
`