import React from 'react'
import styled from 'styled-components'
import { BaseButton } from '../Section'

const PrimaryBtn = styled(BaseButton)`		
	background: #529A00;
	color:#fff;	
	border-radius: 100px;
	&:before{
		content:'';
		transition: all 0.20s ease;
		background:#000;
		transform:scale(0);
		transform-origin:center;
		position:absolute;
		width:100%;
		height:100%;
		left:0;
		right:0;
		top:0;
		bottom:0;
		border-radius: 100px;
		padding:8px;
	}

	& .icon{
		background-color:transparent;
		display: inline-block;
    vertical-align: middle;
		position:relative;
		z-index:1;
		> svg{
			fill:#fff;
		}
	}
	&:hover{
		&:before{
			transform:scale(1);
		}
	}
`

const PrimaryButton = (props) => {
	const { icon, text, iconBefore=false  } = props;
	return(
		<PrimaryBtn className='btn'>
      {iconBefore === true && icon && <span className='icon'>{icon}</span>}
			<span className='text'>{text}</span>
			{iconBefore === false && icon && <span className='icon'>{icon}</span>}
		</PrimaryBtn>
	)
}

export default PrimaryButton