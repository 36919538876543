import React from 'react'
import { Section } from './Section';
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import FacebookIcon from '../components/Icons/FacebookIcon'
// import LineArrowIcon from '../components/Icons/LineArrowIcon'
// import YoutubeIcon from '../components/Icons/YoutubeIcon'
// import VimeoIcon from '../components/Icons/VimeoIcon'
// import InstagramIcon from '../components/Icons/instagramIcon'
import { Link } from 'gatsby'
import BreakpointDown from './Media/BreakpointDown';

const FooterWrapper = styled.div`
  display:flex;
  flex-wrap:wrap;
  justify-content:space-around;
  margin-left:-15px;
  margin-right:-15px;
  ${BreakpointDown.sm`
   margin-bottom:20px;
  `}
`
const FooterLeft = styled.div`
    flex: 0 0 auto;
    width: 41.6666666667%;
    border-right:1px solid #CCCCCC;
    padding-right:15px;
    padding-left:15px;
    @media(max-width:1024px){
      width: auto;
      border-right:0;
    }
    ${BreakpointDown.md`
        flex: 0 0 100%;
        width: 100%;
    `}
`
const FooterRight = styled.div`
  flex: 0 0 auto;
  width: 58.3333333333%;
  display:flex;
  padding-right:15px;
    padding-left:15px;
  justify-content:space-around;
  @media(max-width:1024px){
    width: 100%;
  }
  ${BreakpointDown.md`
    flex: 0 0 auto;
    width: 100%;
    flex-wrap:wrap;
    justify-content:flex-start;
    padding-left:15px;
    padding-right:15px;
  `}
`
const FooterRightitem = styled.div`
${BreakpointDown.lg`
 margin-bottom:15px;
`}
padding-left:15px;
padding-right:15px;
 &:last-child{
   ${BreakpointDown.md`
      flex:0 0 100%;
      display:flex;
      flex-wrap:wrap;
      justify-content:space:between;
      margin-top:20px;
     a{
      flex:0 0 50%;
     }
   `}
 }
`
const FlexColumn = styled.div`
 display:block;
 ${BreakpointDown.sm`
    column-count:2;
 `
  }
`
const FooterTitle = styled.div`
  max-width:500px;
  margin-top:20px;
  ${BreakpointDown.xl`
    margin-top:0;
  `
  }
  @media(min-width:2200px){
   max-width:530px;
  } 
`
// const JoinMial = styled.div`
//   max-width:470px;
//   margin-top:120px;
//   ${BreakpointDown.xl`
//     margin-top:30px;
//   `
//   }

//  button{
//    position:absolute;
//    top:50%;
//    transform:translateY(-50%); 
//    right:20px;
//    svg{
//      transform:rotate(90deg);
//      path{
//       fill:#000;
//      }
//    }
//  }
//  label{
//   color: #333333;
//   margin-bottom: 8px;
//   display:block;
//  }
//  .form-control{
//     min-height:60px; border: 1px solid #CCCCCC;
//     width:100%;
//     padding-left:20px;
//     transition:all 0.3s linear;
//     outline:none;
//     &::placeholder{ 
//      font-size:14px; 
//     }
//  &:hover,
//  &:focus,
//  &:active{
//   box-shadow: 0px 6px 15px #0000001A;
//   border: 1px solid #CCCCCC;
//   outline:none;
//  }
// }
// `
const SocialIocnList = styled.div`
  margin-top:80px;
  @media(max-width:1024px){
    margin-top:40px;
 }
`
const SocialIcon = styled.div`
 display:inline-block;
 & + & {
  margin-left:15px;
 } 
`
// const InputWrapper = styled.div`
//   position:relative;
// `
const CopyRight = styled.div`
 color:#666666;
 font-size:14px;
 @media( min-width:2200px){
   font-size:18px;
 }
 @media(max-width:1024px){
  margin-bottom:50px;
 }
 ${BreakpointDown.md`
   margin-bottom:30px;
 `}
`

const FooterLinkTitle = styled.div`
 font-weight:bold;
 color:#000000;
 margin-bottom:10px;
 ${BreakpointDown.md`
   flex:0 0 100%;
 `}
`
const FooterLink = styled(Link)`
 font-size:14px;
 color:#666666;
 padding-top:6px;
 padding-bottom:6px;
 display:block;
 @media(min-width:2200px){
   font-size:18px;
 }
 ${BreakpointDown.md`
   font-size:12px;
   padding-top:0px;
   padding-bottom:0px;
 `}
 &:hover{
   color:#000;
 }
`
const FooterLogo = styled.div`
  max-width:200px;
 ${BreakpointDown.md`
  
 `}
`

const SocialCopyRight = styled.div`
 
`

function Footer() {
  return (
    <Section className="footer" bgColor="#F8F8F8" pt="100px" pb="100px" xpt="60px" xpb="60px" mpb="40px" mpt="40px">
      <div className="container">
        <FooterWrapper>
          <FooterLeft>
            <FooterLogo>
              <StaticImage
                src="../images/logo.png"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Logo"
                placeholder="blurred"
              />
            </FooterLogo>
            <FooterTitle className="h3">Our team has over 75 years of experience in serving our customers’ needs. We can serve your needs, too!</FooterTitle>
            {/* <JoinMial>
              <form>
                <label htmlFor="email">Join our mailing list to receive future updates</label>
                <InputWrapper>
                  <input type="email" id="email" name="email" className="form-control" placeholder="Enter Your Email Address…" />

                  <button type="submit" aria-label="submit">
                    <LineArrowIcon />
                  </button>
                </InputWrapper>
              </form>
            </JoinMial> */}
            <SocialCopyRight>
              <SocialIocnList>
                <SocialIcon>
                  <a  aria-label="facebook" href="https://www.facebook.com/WYN-Technologies-104146891163427/"><FacebookIcon /></a>
                </SocialIcon>
                {/* <SocialIcon>
                  <Link to="/#"><InstagramIcon /> </Link>
                </SocialIcon>
                <SocialIcon>
                  <Link to="/#"><VimeoIcon /> </Link>
                </SocialIcon>
                <SocialIcon>
                  <Link to="/#"><YoutubeIcon /></Link>
                </SocialIcon> */}
              </SocialIocnList>
              <CopyRight>Copyright © 2021 WYN Technologies Inc - All Rights Reserved.</CopyRight>
            </SocialCopyRight>
          </FooterLeft>
          <FooterRight>
            <FooterRightitem>
              <FooterLinkTitle>Our Services</FooterLinkTitle>
              <FooterLink to='/project-management-for-equipment-installations'>Project Management for Equipment Installations</FooterLink>
              <FooterLink to='/staging-storage-inventory-and-depot-repair-services'>Staging, Storage, Inventory, and Depot Repair Services</FooterLink>
              <FooterLink to='/structured-low-voltage-cabling-network-data-voice'>Structured Low Voltage Cabling (Network/Data/Voice)</FooterLink>
              {/* <FooterLink to='/nvr-camera-surveillance-system-installation-and-sales'>NVR/Camera Surveillance System Installation and Sales</FooterLink> */}
              <FooterLink to='/access-point-heat-mapping-surveys-installation-and-hardware-sales'>Access Point Heat Mapping/Surveys, Installation, and Hardware Sales</FooterLink>
              <FooterLink to='/point-of-sale-installation-and-design'>Point of Sale Installation and Design</FooterLink>
              <FooterLink to='/digital-Signage-installation'>Digital Signage Installation</FooterLink>
              <FooterLink to='/business-telephone-systems-and-contact-centers'>Business Telephone Systems and Contact Centers</FooterLink>
              <FooterLink to='/access-control-installation-and-hardware-sales'>Access Control Installation and Hardware Sales</FooterLink>
              <FooterLink to='/isp-service-brokerage-and-cellular-network-backup-solutions'>ISP Service Brokerage and Cellular Network Backup Solutions</FooterLink>
              <FooterLink to='/data-security-and-cloud-business-solutions'>Data Security and Cloud Business Solutions</FooterLink>
              <FooterLink to='/vehicle-gps-tracking-systems-and-driver-safety'>Vehicle GPS Tracking Systems and Driver Safety</FooterLink>
            </FooterRightitem>
            <FooterRightitem>
              <FooterLinkTitle> Other Links</FooterLinkTitle>
              <FlexColumn>
                <FooterLink to="/case-studies">Case Studies</FooterLink>
                <FooterLink to="/blog">Resources</FooterLink>
                <FooterLink to="/about-us">About WYN</FooterLink>
                <FooterLink to="/faq">FAQs</FooterLink>
                <FooterLink to="/review">Customer Reviews</FooterLink>
                <FooterLink to="/contact-us">Contact Us</FooterLink>
              </FlexColumn>
            </FooterRightitem>
          </FooterRight>
        </FooterWrapper>
      </div>
    </Section>
  )
}
export default Footer;