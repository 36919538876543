import React from 'react'

function CallInfo(){
  return (
  <svg id="info-outline" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
  <path id="Path_19688" data-name="Path 19688" d="M5.4,9H6.6V5.4H5.4ZM6,0a6,6,0,1,0,6,6A6.018,6.018,0,0,0,6,0ZM6,10.8A4.8,4.8,0,1,1,10.8,6,4.814,4.814,0,0,1,6,10.8ZM5.4,4.2H6.6V3H5.4Z" fill="#999"/>
  </svg>

  )
}

export default CallInfo;